import { addMinutes, isEqual, isSameDay, max, isAfter } from 'date-fns'

import connectionsAPI, { ConnectionsRequest, ConnectionsResponse } from '@api/connections'
import config from '@config'
import { Sorting } from '@enums'
import checkoutUtils from '@lib/checkout'
import date from '@lib/date'
import groupConnections from '@lib/groupConnections'
import { makeLoader } from '@lib/loader'
import paramsUtils from '@lib/params'
import sorting from '@lib/sorting'
import utils from '@lib/utils'
import { ParamsStore } from '@stores/params'

interface CheckoutConnectionsResponse {
  inbound?: Connection
  outbound?: Connection
}

interface ConnectionDateParams {
  departureDate: string | null
  departureTime: string | null
  arrivalDate: string | null
  arrivalTime: string | null
}

const DELAY = 10
const EXPRESS_DELAY_MINUTES = 6

const findConnectionByDate = (connections: Connection[], params: ConnectionDateParams): Connection | undefined => {
  const departureDate = date.parseUTCDateTime(params.departureDate, params.departureTime, 'UTC')
  const arrivalDate = date.parseUTCDateTime(params.arrivalDate, params.arrivalTime, 'UTC')
  const validateDate = (date: Date | null, connectionDate: Date, exact: boolean): boolean => {
    if (!date) return true

    return exact ? isEqual(date, connectionDate) : isSameDay(date, connectionDate)
  }

  const validateConnection = (connection: Connection): boolean =>
    validateDate(departureDate, date.parse(connection.departureTime, 'UTC'), !!params.departureTime) &&
    validateDate(arrivalDate, date.parse(connection.arrivalTime, 'UTC'), !!params.arrivalTime)

  return connections.find(validateConnection)
}

const findClosestConnection = (connections: Connection[]): Connection | undefined =>
  sorting.sortConnectionList(connections, Sorting.DepartureTime).find(c => {
    const stationTime = date.parse(c.departureTime, 'UTC')

    return !c.bookedOut && isAfter(stationTime, addMinutes(date.today(), EXPRESS_DELAY_MINUTES))
  })

interface TimeParams {
  departureEndTime: string | null
  departureStartTime: string | null
}

const getDepartureTimes = (params: ParamsStore): TimeParams => ({
  departureEndTime: params.express ? null : params.departureEndTime ?? params.departureTime,
  departureStartTime: params.departureTime,
})

const createConnectionParams = (params: ParamsStore): ConnectionsRequest => {
  return utils.object.compact<ConnectionsRequest>({
    ...(params.departureLocation && paramsUtils.flatLocation(params.departureLocation, 'departure')),
    ...(params.arrivalLocation && paramsUtils.flatLocation(params.arrivalLocation, 'arrival')),
    ...(params.returnDepartureDate ? {} : getDepartureTimes(params)),
    currency: params.currency,
    departureDate: params.departureDate,
    marketingCarrierCode: params.marketingCarrierCode,
    locale: params.locale,
    pax: params.pax,
    retailerPartnerNumber: params.retailerPartnerNumber,
    returnDate: params.returnDepartureDate,
    passengers: params.passengers && checkoutUtils.getConnectionPassengers(params.passengers),
    cards: params.cards,
    bookingId: params.bookingId,
  })
}

const updateExpressParams = (params: ParamsStore): ParamsStore => {
  const paramsDate = date.parseUTCDateTime(params.departureDate, params.departureTime)
  const closestDate = addMinutes(date.today(), DELAY)
  const departureDate = max([paramsDate, closestDate])

  return {
    ...params,
    departureTime: date.formatTime(departureDate, true),
  }
}

const findOutboundConnection = (connections: Connection[], params: ParamsStore): Connection | undefined =>
  params.express && !params.returnDepartureDate
    ? findClosestConnection(connections)
    : findConnectionByDate(connections, params)

const findInboundConnection = (connections: Connection[], params: ParamsStore): Connection | undefined => {
  const dateParams: ConnectionDateParams = {
    departureDate: params.returnDepartureDate,
    arrivalDate: params.returnArrivalDate,
    departureTime: params.returnDepartureTime,
    arrivalTime: params.returnArrivalTime,
  }

  return findConnectionByDate(connections, dateParams)
}

const loadTwoStepReturn = async (params: ParamsStore): Promise<CheckoutConnectionsResponse> => {
  const outbounds = await connectionsAPI.load({
    ...createConnectionParams(params),
    ...getDepartureTimes(params),
    twoStepReturnSearch: true,
  })
  const outbound = findOutboundConnection(outbounds, params)

  const returnFareId = outbound?.fares.find(({ fareClass }) => fareClass.code === params.returnFareClass)?.id
  let inbounds: ConnectionsResponse
  // istanbul ignore else
  if (returnFareId) {
    inbounds = await connectionsAPI.load({
      ...createConnectionParams({
        ...params,
        departureLocation: params.arrivalLocation,
        arrivalLocation: params.departureLocation,
        returnDepartureDate: null,
        returnDepartureTime: null,
        returnDate: null,
        departureDate: params.returnDepartureDate as string,
        departureTime: params.returnDepartureTime,
      }),
      returnDate: null,
      offerId: returnFareId,
      twoStepReturnSearch: true,
    })
  } else {
    inbounds = []
  }

  return {
    outbounds,
    outbound: findOutboundConnection(outbounds, params),
    inbound: findInboundConnection(inbounds, params),
  } as any
}

export const useCheckoutConnectionsLoader = makeLoader<ParamsStore, CheckoutConnectionsResponse>({
  key: 'checkoutConnection',
  enabled: ({ departureLocation, arrivalLocation }) =>
    [departureLocation, arrivalLocation].every(value => value != null),
  loader: async params => {
    const isTwoStepSearchAllowed =
      config.twoStepSearch.byRpn.includes(params.retailerPartnerNumber) && params.returnDepartureDate
    const isAvoidVacancyAmendment =
      config.amendments.avoidVacancy.byRpn.includes(params.retailerPartnerNumber) &&
      /* istanbul ignore next */ params.returnDepartureDate

    if (isTwoStepSearchAllowed || isAvoidVacancyAmendment) {
      return loadTwoStepReturn(params)
    }

    const { returnArrivalDate, departureLocation, arrivalLocation, express } = params
    const expressParams = updateExpressParams(params)
    const requestParams = createConnectionParams(express ? { ...params, ...expressParams } : params)
    const connections = await connectionsAPI.load(requestParams)
    const { inbounds, outbounds } = groupConnections(connections, {
      departureLocation,
      arrivalLocation,
      returnDate: returnArrivalDate,
    })

    return {
      outbounds,
      outbound: findOutboundConnection(outbounds, params),
      inbound: findInboundConnection(inbounds, params),
    }
  },
})
