import React, { ReactElement } from 'react'

import ancillaryUtils from '@lib/ancillary'
import AncillaryCounter from '@pages/Checkout/Extras/Ancillary/components/Controls/Counter'
import InlineAncillary from '@pages/Checkout/Extras/Ancillary/components/Inline'
import { DefaultProps } from '@pages/Checkout/Extras/Ancillary/Extended'
import Ancillary from '@pages/Checkout/Extras/Ancillary/index'

export interface ResolverProps extends DefaultProps {
  ancillaries?: Ancillary.Item[]
  controlsOverride?: ReactElement
}

const Resolver = ({ ancillaries, name, controlsOverride, ...rest }: ResolverProps): ReactElement => {
  const isSingle = [...new Set(ancillaryUtils.getByCategory(name, ancillaries).map(({ code }) => code))].length === 1
  const controls = controlsOverride || <AncillaryCounter />

  if (isSingle) return <InlineAncillary name={name} controls={controls} />

  return <Ancillary.Extended name={name} {...rest} />
}

export default Resolver
