import React, { useState } from 'react'

import cookies from '@lib/cookies'
import { useTranslation } from '@lib/i18n'
import { Button } from '@ui'

import '@components/CookiesConsent/index.scss'

const CookiesConsent = () => {
  const { t } = useTranslation()
  const [accepted, setAccepted] = useState(() => cookies.consent.get())
  const accept = () => {
    setAccepted(true)
    cookies.consent.set()
  }

  if (accepted) return null

  return (
    <div className="cookies-consent">
      <div className="column">
        <h4 className="mb-0">{t('cookiesConsent.title')}</h4>
        <div className="row-lg column gap-5">
          <div className="mt-1 body-14">{t('cookiesConsent.description')}</div>
          <div className="cell cookies-consent__actions">
            <div className="column gap-3">
              <div className="cell">
                <Button onClick={accept}>{t('cookiesConsent.accept')}</Button>
              </div>
              <a href="https://distribusion.com/booking-engine-cookies" target="_blank" rel="noreferrer">
                <Button variant="outline" color="secondary">
                  {t('cookiesConsent.moreInfo')}
                </Button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CookiesConsent
