import camelCase from 'camelcase'
import React, { Fragment, ReactElement, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import Connection from '@components/Connection'
import CarrierInfo from '@components/TripInfo/CarrierInfo'
import CarrierLogo from '@components/TripInfo/CarrierLogo'
import StationInfo from '@components/TripInfo/StationInfo'
import TransferTime from '@components/TripInfo/TransferTime'
import TripConditions from '@components/TripInfo/TripConditions'
import useIsMobile from '@hooks/useIsMobile'
import connectionUtils from '@lib/connection'
import currencyUtils from '@lib/currency'
import fareUtils from '@lib/fare'
import fareClassUtils from '@lib/fareClass'
import fareFeaturesUtils from '@lib/fareFeature'
import { getValidity } from '@pages/Checkout/BookingDetails/TripDetails/ConnectionDetails/utils'
import { UpdatedMediaData } from '@queries/media'
import { useSettings } from '@queries/settings'
import { Icon, MediaCarousel } from '@ui'

import '@components/TripInfo/index.scss'

interface TripInfoProps {
  connection: Connection
  fareClass: string
  type?: ConnectionType
  carriers?: MarketingCarrier[]
  hideTime?: boolean
  media?: UpdatedMediaData[]
}

const TripInfo = ({ connection, type, fareClass, carriers = [], hideTime, media }: TripInfoProps): ReactElement => {
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const [{ carrierLogo, vehicleIdentifier }] = useSettings()

  const fareClassNames = useMemo(
    () => fareUtils.getUniqueFareClassNames([connection], { fareClassCode: fareClass }),
    [connection, fareClass],
  )

  const findFareFeatures = (segment: Segment): FareFeature[] => fareUtils.getSegmentFareFeatures(segment, fareClass)

  return (
    <div className="trip-info column">
      {connection.segments.map((segment, index) => {
        const next = connection.segments[index + 1]
        const previous = connection.segments[index - 1]
        const carrier = segment[camelCase(carrierLogo.source) as keyof Segment] as Carrier
        const isSelfTransfer = connectionUtils.isSelfTransfer(segment)
        const segmentCarrier = carriers.filter(item => item.code === carrier.code)
        const fee = connection.marketingCarrier.bookingFee
        const isLastSegment = index == connection.segments.length - 1
        const filteredMedia = media?.reduce<string[]>((acc, curr) => {
          if (curr.marketingCarrierCode !== carrier.code) return acc

          return [...curr.images, ...curr.videos, ...fareClassUtils.getMedia(curr, fareClass)]
        }, [])
        const transferArrival = isSelfTransfer ? previous : segment

        return (
          <Fragment key={segment.id}>
            {!isSelfTransfer && (
              <div className="trip-info__wrapper row-lg">
                {isMobile && <CarrierLogo name={carrier.tradeName} code={carrier.code} />}
                {isMobile && !!filteredMedia?.length && <MediaCarousel media={filteredMedia} />}
                <div className="cell-lg-5 column">
                  <StationInfo
                    type="departure"
                    station={segment.departureStation}
                    time={hideTime ? undefined : segment.departureTime}
                    duration={
                      hideTime ? undefined : (
                        <Connection.Duration
                          departureTime={segment.departureTime}
                          arrivalTime={segment.arrivalTime}
                          departureTimezone={segment.departureStation.timeZone}
                          arrivalTimezone={segment.arrivalStation.timeZone}
                        />
                      )
                    }
                    vehicle={segment.vehicle?.vehicleType?.code}
                  />
                  <StationInfo
                    type="arrival"
                    station={segment.arrivalStation}
                    time={hideTime ? undefined : segment.arrivalTime}
                    vehicle={segment.vehicle?.vehicleType?.code}
                  />
                </div>
                <div className="trip-info__carrier cell-lg-6">
                  <CarrierInfo
                    carrier={carrier}
                    fareFeatures={findFareFeatures(segment).filter(item => !fareFeaturesUtils.hasRefundFeatures(item))}
                    fareClassNames={fareClassNames}
                    line={segment.line}
                    linePrefix={segment.linePrefix}
                    lineDataEnabled={vehicleIdentifier.enabled}
                    connection={connection}
                    media={filteredMedia ?? []}
                  />
                </div>
              </div>
            )}
            <div className="cell mt-2">
              <TripConditions
                fareFeatures={findFareFeatures(segment).filter(fareFeaturesUtils.hasRefundFeatures)}
                validity={getValidity(segmentCarrier, type)}
                description
              />
            </div>
            {fee.fractional > 0 && isLastSegment && (
              <div className="row items-center pt-2 trip-info__fee">
                <Icon name="info" size="medium" className="mr-1" />
                <span className="body-14">
                  {t('card.fee', { fee: currencyUtils.create(fee.fractional, fee.currency).format() })}
                </span>
              </div>
            )}
            {!isLastSegment &&
              !connectionUtils.isSelfTransfer(next) &&
              next.departureTime !== transferArrival.arrivalTime && (
                <TransferTime
                  departureTime={next.departureTime}
                  departureTimezone={next.departureStation.timeZone}
                  arrivalTime={transferArrival.arrivalTime}
                  arrivalTimezone={transferArrival.arrivalStation.timeZone}
                  selfTransfer={isSelfTransfer}
                />
              )}
          </Fragment>
        )
      })}
    </div>
  )
}

export default TripInfo
