import React, { ReactElement, ReactNode } from 'react'

import Loader from '@components/Loader'
import bem from '@lib/bem'

export interface CarouselItemConfig {
  id: string | number | Date
  isSelected: boolean
  isLoading: boolean
  title: (params: any) => ReactNode
  description?: (params: any) => ReactNode
}

interface ItemProps {
  itemConfig: CarouselItemConfig
  isSelected: boolean
  onChange: () => void
}

const Item = ({ itemConfig, isSelected, onChange }: ItemProps): ReactElement => {
  const { isLoading, title, description } = itemConfig

  const classNames = bem('list-item', { selected: isSelected })

  const onClick = (): void => {
    /* istanbul ignore else */
    if (!isLoading) onChange()
  }

  return (
    <button className={classNames} onClick={onClick} data-tag="connections-carousel-item">
      <Loader loading={isLoading}>
        <div className={bem('list-item__primary-text')}>{title(itemConfig.id)}</div>
        {description && <div className={bem('list-item__secondary-text')}>{description(itemConfig.id)}</div>}
      </Loader>
    </button>
  )
}

export default Item
