import { PaymentMethodsResponseObject } from '@adyen/adyen-web/core/ProcessResponse/PaymentMethodsResponse/types'
import { PaymentAction } from '@adyen/adyen-web/types'

import makeApi, { ResponsePostprocessing } from '@lib/api'
import { transformResponse } from '@lib/api/transformers/booking'

type TicketType = 'qr-code-png' | 'pdf' | 'google-wallet'

export type BookingResponse = Booking.Data

export type BookingConfirmationResponse = Booking.Confirmation

export type BookingCancellationResponse = Booking.Cancellation

export interface BookingByIdRequest {
  locale: Locale
  retailerPartnerNumber: RetailerNumber
}

export interface BookingConfirmationRequest {
  locale: Locale
  retailerPartnerNumber: RetailerNumber
}

export interface BookingCancellationRequest {
  bookingId: string
}

export interface FindBookingRequest {
  locale: string
  email: string
  lastName: string
  distribusionBookingNumber: string
  retailerPartnerNumber?: RetailerNumber | null
}

export type BookingQrResponse = Booking.Qr[]

export interface BookingQrRequest {
  type: TicketType
}

interface BookingWalletRequest {
  type: TicketType
}

export interface BookingWalletResponse {
  url: string
}

export interface PaymentMethodsRequest {
  retailerPartnerNumber: number
  marketingCarrierCode: string
  currency: Currency
  amount: number
}

export type PaymentMethodsResponse = PaymentMethodsResponseObject['paymentMethods']

export interface PassengerSeat {
  segmentIndex: number
  seatCode: string
}

export interface GovernmentIdParams {
  governmentId?: string | null
  governmentIdType?: string | null
  governmentIdIssuingCountry?: string | null
  governmentIdIssuingState?: string | null
  governmentIdValidityExpiration?: string | null
  nationality?: string | null
}

export interface BookingPassenger extends GovernmentIdParams {
  type?: string
  pax?: number
  lastName?: string
  firstName?: string
  birthdate?: string | null
  seats?: PassengerSeat[] | null
  ancillaries?: BookingAncillaries[] | null
}

export interface BookingAncillaries {
  code: string
  price: number
  quantity: number
  segmentIndex?: number | null
  seatCode?: string
}

export interface BookingCreateRequest {
  arrivalStationCode: string
  departureStationCode: string
  marketingCarrierCode: string
  arrivalTime: string
  departureTime: string
  currency: Currency
  locale: Locale
  amount: number
  paymentMethod: string
  paymentProvider: string
  retailerPartnerNumber: number
  passengers: BookingPassenger[]
  description?: string
  discountCode?: string
  email?: string
  phone?: string | null
  termsAccepted?: boolean
  sendMarketingEmails?: boolean
  pax?: number
  externalSessionId?: string
  fareClass?: string | null
  returnDepartureTime?: string
  returnArrivalTime?: string
  cardHolderName?: string | null
  sourceId?: string
  markupFee: number
  paymentIntentId?: string
  express?: boolean
  paymentAttemptReference?: string
  profilingReference?: string
  numberOfInstallments?: number
  cpf?: string | null
  cardData?: object
  paymentMethodData?: object
  shopperStatement?: string
  browserInfo?: object
  ancillaries?: BookingAncillaries[]
  deviceFingerprint?: string | null
  retailerBookingNumber?: string | null
  originalPrice?: number | null
  shopperReference?: string | null
  paymentToken?: string | null
}

export interface BookingCreateResponse {
  action: BookingAction
  bookingFormId: number
  redirectPath: string
  bookingStatus: string
  details: {
    action: PaymentAction
  }
}

export interface BookingFinalizeRequest {
  paymentDetails: {
    details: {
      threeDSResult: string
    }
  }
}

export type BookingFinalizeResponse = BookingCreateResponse

export interface BookingStatusResponse {
  bookingStatus: Booking.Status
  redirectPath: string
  receiptNumber: string
}

const confirmationConfig: ResponsePostprocessing = {
  convertValues: {
    totalPrice: 'Price',
    amount: 'Price',
  },
}

export default {
  loadById: async (id: string, params: BookingByIdRequest) => {
    const callApi = makeApi.get<BookingByIdRequest, BookingResponse>(`/bookings/${id}`)

    return await callApi(params)
  },
  confirmation: async (id: string, params: BookingConfirmationRequest) => {
    const callApi = makeApi.get<BookingConfirmationRequest, BookingConfirmationResponse>(
      { type: 'confirmation', old: `/bookings/${id}/confirmation`, new: `/booking/${id}/confirmation` },
      {
        responsePostprocessing: confirmationConfig,
        transformResponse: <T, U>(response: T, rawResponse: U) =>
          transformResponse(response as BookingConfirmationResponse, rawResponse) as T,
      },
    )

    return await callApi(params)
  },
  loadQrCodes: async (id: string) => {
    const callApi = makeApi.get<BookingQrRequest, BookingQrResponse>({
      type: 'tickets',
      old: `/bookings/${id}/tickets`,
      new: `/booking/${id}/tickets`,
    })

    return await callApi({ type: 'qr-code-png' })
  },
  loadGoogleWallet: async (id: string) => {
    const callApi = makeApi.get<BookingWalletRequest, BookingWalletResponse>({
      type: 'tickets',
      old: `/bookings/${id}/tickets`,
      new: `/booking/${id}/tickets`,
    })

    return await callApi({ type: 'google-wallet' })
  },
  find: makeApi.get<FindBookingRequest, BookingResponse>('/bookings'),
  cancel: async (bookingId: string) => {
    const callApi = makeApi.post<{}, {}, BookingCancellationResponse>({
      type: 'cancellations',
      old: `/bookings/${bookingId}/cancellations`,
      new: `/booking/${bookingId}/cancellations`,
    })

    return await callApi({ body: {}, params: {} })
  },
  paymentMethods: makeApi.get<PaymentMethodsRequest, PaymentMethodsResponse>('/bookings/payment_methods'),
  create: makeApi.post<{}, BookingCreateRequest, BookingCreateResponse>('/bookings'),
  finalize: async (bookingFormId: number, body: BookingFinalizeRequest) => {
    const url = `/booking_forms/${bookingFormId}/finalization`
    const callApi = makeApi.post<{}, BookingFinalizeRequest, BookingFinalizeResponse>(url)

    return await callApi({ body, params: {} })
  },
  status: async (id: number): Promise<BookingStatusResponse> => {
    const callApi = makeApi.get<{}, BookingStatusResponse>(`/booking_forms/${id}/status`)

    return await callApi({})
  },
}
