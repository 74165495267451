import makeApi from '@lib/api'

export interface Response {
  bookingStatus: Booking.Status
  redirectPath: string
  receiptNumber: string
}

export const status = async (id: number): Promise<Response> => {
  const callApi = makeApi.get<{}, Response>({
    type: 'reservations',
    old: `/booking_forms/${id}/status`,
    new: `/reservations/${id}/status`,
  })

  return await callApi({})
}
