import { useCallback } from 'react'
import { useNavigate as useStandardNavigate, createSearchParams } from 'react-router-dom'

import params from '@lib/params'
import qs from '@lib/queryString'
import { useSettings } from '@queries/settings'
import { useCheckout } from '@stores/checkout'
import { useParams } from '@stores/params'

interface NavigateHook {
  navigate: (url: string) => void
  checkoutRedirect: (url: string) => void
}

const useNavigate = (): NavigateHook => {
  const standardNavigate = useStandardNavigate()
  const [_, setParams] = useParams()
  const [settings] = useSettings()
  const [, , { reset: resetCheckout }] = useCheckout()

  const navigate = useCallback(
    (url: string) => {
      const urlParams = createSearchParams(url.split('?')[1])
      const parsedParams = qs.parse(Object.fromEntries([...urlParams.entries()]))

      const normalisedParams =
        parsedParams &&
        params.normaliseParams({
          retailerPartnerNumber: parseInt(parsedParams.retailerPartnerNumber),
          params: parsedParams,
          settings: settings,
        })
      setParams(normalisedParams)

      standardNavigate(url)
    },
    [standardNavigate, setParams, settings],
  )

  const checkoutRedirect = useCallback(
    (url: string) => {
      if (url.startsWith(location.origin) && settings.checkout.type !== 'express') {
        resetCheckout()
        navigate(url.split(location.origin)[1])
      } else {
        window.location.href = url
      }
    },
    [settings.checkout.type, resetCheckout, navigate],
  )

  return {
    navigate,
    checkoutRedirect,
  }
}

export default useNavigate
