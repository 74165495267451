import makeApi from '@lib/api'

export interface PriceHintsRequest {
  departureStation: string
  arrivalStation: string
  currency: Currency
  departureTime: string
}

export interface PriceHintsResponse {
  referencePrice: number
  referencePriceThreshold: number // any price below this threshold is considered "lower than usual"
}

const priceInsightsApi = {
  hints: makeApi.get<PriceHintsRequest, PriceHintsResponse>('/price_insights/price_hints'),
}

export default priceInsightsApi
