import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { useFormikContext } from 'formik'
import { useMemo } from 'react'

import priceInsightsApi, { PriceHintsRequest, PriceHintsResponse } from '@api/priceInsights'
import abTest from '@lib/abTest'
import checkoutUtils from '@lib/checkout'
import date from '@lib/date'
import { CheckoutFormData } from '@pages/Checkout/hooks/useInitialFormValues'
import { useSettings } from '@queries/settings'
import { useCheckout } from '@stores/checkout'
import { useParams } from '@stores/params'

interface PriceTrend {
  absolute: number
  relative: number
  lowerThanUsual: boolean
}

export interface PriceHintsCheckoutData extends PriceTrend {
  isVisible: boolean
  loader: UseQueryResult<PriceHintsResponse>
}

export const getPriceTrendBreakdown = (price: number, priceHints: PriceHintsResponse): PriceTrend => {
  const absolute = priceHints.referencePrice - price
  const relative = absolute / priceHints.referencePrice

  return {
    absolute,
    relative,
    lowerThanUsual: price < priceHints.referencePriceThreshold && (absolute > 100 || relative > 0.3),
  }
}

const usePriceHints = (): PriceHintsCheckoutData => {
  const [{ outbound, inbound }] = useCheckout()
  const [{ currency }] = useParams()
  const [{ priceHints: priceHintsSetting }] = useSettings()
  const { values } = useFormikContext<CheckoutFormData>()

  const priceHintsParams = useMemo(
    () => ({
      departureStation: outbound?.departureStation.code,
      arrivalStation: outbound?.arrivalStation.code,
      departureTime: outbound?.departureTime && date.formatDateISO(date.parse(outbound.departureTime, 'UTC')),
      currency: currency,
    }),
    [outbound, currency],
  )
  const hintsEnabled = priceHintsSetting.enabled && !inbound && !!outbound && values.passengers.length === 1
  const priceHints = useQuery({
    queryKey: ['priceHints', hintsEnabled, priceHintsParams],
    queryFn: () => priceInsightsApi.hints(priceHintsParams as PriceHintsRequest),
    enabled: hintsEnabled,
  })

  return useMemo(() => {
    if (!priceHints.data || !values.price)
      return { absolute: 0, relative: 0, isVisible: false, loader: priceHints, lowerThanUsual: false }

    const totalPrice = checkoutUtils.calculateTotalFees(values.fees) + values.price?.fractional
    const { absolute, relative, lowerThanUsual } = getPriceTrendBreakdown(totalPrice, priceHints.data)

    return {
      absolute,
      relative,
      lowerThanUsual,
      isVisible: !abTest.featureState.disabled('priceHints') && lowerThanUsual,
      loader: priceHints,
    }
  }, [priceHints, values.fees, values.price])
}

export default usePriceHints
