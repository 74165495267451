import React, { useCallback, useMemo } from 'react'

import { PaymentMethodStatus } from '@enums'
import useMoovitIntegration from '@hooks/useMoovitIntegration'
import { CheckoutFormData } from '@pages/Checkout/hooks/useInitialFormValues'
import PaymentLabel from '@pages/Checkout/Payment/Label'

const brands = [{ type: 'visa' }, { type: 'mc' }, { type: 'me' }]

export const useMoovitCard = (): AdyenTypes.Method => {
  const { getTokenData } = useMoovitIntegration()

  const getOption = useCallback(
    () => ({
      value: 'stored_payment',
      label: <PaymentLabel type="moovitCreditCard" brands={brands} showName />,
    }),
    [],
  )

  return useMemo(
    () => ({
      status: PaymentMethodStatus.Ready,
      getOption,
      isSubmitting: false,
      on: {
        submitForm: async (data: CheckoutFormData) => {
          /* istanbul ignore next */
          if (!data.price) return

          const token = await getTokenData(data.price)

          return {
            ...data,
            paymentMethodData: {
              type: 'scheme',
              storedPaymentMethodId: token.paymentMethodToken,
            },
            shopperReference: token.paymentUserId,
            paymentToken: token.paymentMethodId,
          }
        },
      },
    }),
    [getOption, getTokenData],
  )
}
