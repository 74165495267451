import { useFormikContext } from 'formik'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import JourneyCardLayout from '@components/JourneyCard/Layout'
import SkeletonCard from '@components/JourneyCard/Skeleton'
import { ResourceContext } from '@components/RequiredResource'
import useSearchParams from '@hooks/useSearchParams'
import noSeats from '@images/extras/no-seats.png'
import bem from '@lib/bem'
import date from '@lib/date'
import { useTranslation } from '@lib/i18n'
import url from '@lib/url'
import utils from '@lib/utils'
import { CheckoutFormData } from '@pages/Checkout/hooks/useInitialFormValues'
import { useSettings } from '@queries/settings'
import { useCheckout } from '@stores/checkout'
import { useParams } from '@stores/params'
import { Button, Modal } from '@ui'

import '@pages/Checkout/NewConnections/index.scss'

const CONNECTIONS_LIMIT = 2
const NO_CONNECTIONS = 0
const CITY_CODE_LENGTH = 5

const NewConnections = (): ReactElement => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [opened, setOpened] = useState<boolean>(false)
  const [params, setParams] = useParams()
  const [queryParams, setQueryParams] = useSearchParams()
  const [{ outbounds, outbound }, setCheckout] = useCheckout()
  const [{ fareClasses, reservation }] = useSettings()
  const {
    values: { vacancy, isVacancyLoading },
  } = useFormikContext<CheckoutFormData>()
  const { isLoading } = useContext(ResourceContext)

  const isFareClassSelection = fareClasses.displayOn !== 'nowhere' && outbound && outbound.fares.length > 1
  const isNotVacant = vacancy && !vacancy.vacant && !isFareClassSelection
  const disallowRequest = reservation.enabled || params.returnDepartureDate || isFareClassSelection

  const errorKey = vacancy && !vacancy.vacant ? 'seats' : 'connections'
  const errorText = {
    seats: {
      title: t('checkout.noTrips.noSeats'),
      message: t('checkout.noTrips.noSeatsMessage'),
    },
    connections: {
      title: t('checkout.noTrips.noConnections'),
      message: t('checkout.noTrips.noConnectionsMessage'),
    },
  }

  useEffect(() => {
    if (isLoading || isVacancyLoading) return

    if (!outbound || isNotVacant) {
      setOpened(true)
      !disallowRequest && setParams({ departureEndTime: date.endOfDay() })
    }
  }, [disallowRequest, isLoading, isNotVacant, isVacancyLoading, outbound, setParams])

  const count = disallowRequest ? NO_CONNECTIONS : CONNECTIONS_LIMIT
  const connections = outbounds
    ?.filter(connection => !connection.bookedOut && connection.id !== outbound?.id)
    .slice(0, count)

  const handleRedirect = () => {
    const { retailerPartnerNumber, marketingCarrierCode, departureDate, locale, currency, parentDomain } = params
    const parameters = utils.object.compact({
      retailerPartnerNumber,
      marketingCarrierCode,
      departureDate,
      locale,
      currency,
      departureTime: date.startOfDay(),
      returnDate: params.returnArrivalDate,
      departureCity: params.departureLocation?.code.slice(0, CITY_CODE_LENGTH),
      arrivalCity: params.arrivalLocation?.code.slice(0, CITY_CODE_LENGTH),
      parentDomain,
    })

    navigate(url.build(['/', 'result'], { ...parameters }))
  }

  const openConnectionPage = (connection: Connection, fareClass: string): void => {
    const departureTime = date.parse(connection.departureTime, 'UTC')
    const arrivalTime = date.parse(connection.arrivalTime, 'UTC')

    const parameters = utils.object.compact({
      ...queryParams,
      fareClass,
      departureDate: date.formatDate(departureTime),
      departureTime: date.formatTime(departureTime),
      departureStation: connection.departureStation.code,
      arrivalDate: date.formatDate(arrivalTime),
      arrivalTime: date.formatTime(arrivalTime),
      arrivalStation: connection.arrivalStation.code,
      marketingCarrierCode: connection.marketingCarrier.code,
      departureEndTime: date.formatTime(departureTime),
    })

    setQueryParams(parameters)
    setOpened(false)
    setCheckout({ outbounds: null })
  }

  const header = (
    <div className="column items-center">
      <img className="mb-5" src={noSeats} alt="no seats" />
      <h3 className="mb-2">{errorText[errorKey].title}</h3>
      <span className="body-16">{errorText[errorKey].message}</span>
    </div>
  )

  const footer = (
    <div className="p-3 cell-12 cell-lg-4">
      <Button onClick={handleRedirect}>{t('checkout.noTrips.seeMoreTrips')}</Button>
    </div>
  )

  return (
    <Modal
      className={bem('new-connections-modal', { empty: connections?.length === 0 })}
      title
      header={header}
      opened={opened}
      footer={footer}
      fullScreen
    >
      <div className="p-5 column gap-4">
        {isLoading && <SkeletonCard />}
        {connections?.map(connection => (
          <div key={connection.id} className="cell">
            <JourneyCardLayout
              connection={connection}
              onSelect={fareClass => openConnectionPage(connection, fareClass)}
              fareClassFilter={params.fareClass}
              isInbound={false}
              disableFareFilter={false}
            />
          </div>
        ))}
      </div>
    </Modal>
  )
}

export default NewConnections
