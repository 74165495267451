import React, { useMemo } from 'react'

import bem from '@lib/bem'
import fareUtils from '@lib/fare'
import seatSelectionUtils from '@lib/seatSelection'
import utils from '@lib/utils'
import { Tabs } from '@ui'

interface Props {
  connection: Connection | null
  segmentLayout: Seat.Data
  value: string
  onChange: (value: string) => void
}

const SeatsFareClasses = ({ connection, value, onChange, segmentLayout }: Props) => {
  const faresTabs = useMemo(
    () =>
      seatSelectionUtils
        .getUniqueFares(segmentLayout)
        .map(item => ({
          key: item,
          label: utils.string.capitalize(
            fareUtils.getFareByCode(item, connection)?.fareClass.name ?? /* istanbul ignore next */ '',
          ),
        }))
        .filter(item => item.label),
    [connection, segmentLayout],
  )

  return (
    <div className={bem('seat-selection', 'tabs-fares')}>
      <Tabs options={faresTabs} value={value} onClick={onChange} />
    </div>
  )
}

export default SeatsFareClasses
