import React, { ReactElement, ReactNode, useMemo } from 'react'
import { Trans } from 'react-i18next'

import config from '@config'
import useValidators from '@hooks/useValidators'
import ancillaryUtils from '@lib/ancillary'
import passengerUtils from '@lib/passengers'
import { BookingFormData } from '@pages/Booking/hooks/useInitialFormValues'
import Ancillaries from '@pages/Checkout/Extras/Ancillary/All'
import { DefaultProps } from '@pages/Checkout/Extras/Ancillary/Extended'
import Ancillary from '@pages/Checkout/Extras/Ancillary/index'
import MockedAncillary from '@pages/Checkout/Extras/Ancillary/mocked/index'
import ExtrasSkeleton from '@pages/Checkout/Extras/Skeleton'
import { CheckoutFormData } from '@pages/Checkout/hooks/useInitialFormValues'
import { useShowExtras } from '@pages/Checkout/hooks/useShowExtras'
import { useParams } from '@stores/params'
import { Skeleton } from '@ui'

interface AncillaryFieldSetProps {
  isLoading: boolean
  formData: CheckoutFormData | BookingFormData
  seatsError: ErrorCode | null
  outbound: Connection | null
  inbound: Connection | null
}

const ancillariesOrder = [
  'HELP',
  'LEAD',
  'PETS',
  'INSURANCE',
  'EQUIPMENT',
  'BICYCLE',
  'SEAT',
  'LUGGAGE',
  'VEHICLE',
  'MEAL',
  'CARBON',
  'METRO',
  'BOARDING',
  'LOUNGE',
  'FASTTRACK',
]

type ConfigOptions = Pick<DefaultProps, 'getDescription' | 'getMaxCount' | 'validate'>

const AncillaryFieldSet = ({ isLoading, formData, seatsError, ...rest }: AncillaryFieldSetProps): ReactElement => {
  const [{ retailerPartnerNumber }] = useParams()
  const { availableExtras } = useShowExtras({
    seatsError,
    values: formData,
    ...rest,
  })
  const sortedAncillaries = useMemo(
    () => availableExtras.sort((a, b) => ancillariesOrder.indexOf(a) - ancillariesOrder.indexOf(b)),
    [availableExtras],
  )
  const { validateVehicle, validateMeal } = useValidators()
  const passengerCount = ancillaryUtils.getMaxCount(formData.passengers, passengerUtils.getPassengerCode(['infant']))
  const isTestAncillariesEnabled = config.forcedExtras[retailerPartnerNumber]?.testAncillaries?.enabled
  const getCarbonDescription = (ancillary: Ancillary.Item): ReactNode => {
    const description = ancillaryUtils.getCarbonDescription(ancillary)

    return (
      description &&
      description.startsWith('http') && (
        <Trans
          i18nKey="extras.ancillary.carbon.terms"
          components={{ terms: <a target="_blank" rel="noreferrer" href={description} /> }}
        />
      )
    )
  }
  const ancillaryConfig: Record<string, ConfigOptions> = {
    LUGGAGE: { getDescription: ancillaryUtils.getLuggageDescription },
    MEAL: { validate: validateMeal(passengerCount) },
    VEHICLE: { getMaxCount: ancillaryUtils.getVehicleMaxCount, validate: validateVehicle },
  }

  return (
    <Skeleton.List direction="column" divider Skeleton={ExtrasSkeleton} amount={3} loading={isLoading}>
      <>
        {sortedAncillaries.map(category => {
          const Component = Ancillaries[category]

          return Component ? (
            <Component
              key={category}
              name={category}
              ancillaries={formData.vacancy?.ancillaries}
              {...rest}
              {...ancillaryConfig[category]}
            />
          ) : null
        })}
        {availableExtras.includes('CARBON') && (
          <Ancillary.Extended
            {...rest}
            name="CARBON"
            getMaxCount={ancillaryUtils.getCarbonMaxCount}
            getDescription={getCarbonDescription}
          />
        )}
        {isTestAncillariesEnabled && MockedAncillary[retailerPartnerNumber]}
      </>
    </Skeleton.List>
  )
}

export default AncillaryFieldSet
