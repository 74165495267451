import { isAfter, isSameDay } from 'date-fns'
import { useCallback } from 'react'

import date from '@lib/date'
import { useParams } from '@stores/params'

const filterFares = (connection: Connection, fareClassCode: string): Connection => ({
  ...connection,
  fares: connection.fares.filter(fare => fare.fareClass.code === fareClassCode),
})

export const useInboundFilter = (outbound?: Connection, fareClassFilter?: string | null) => {
  const [params] = useParams()

  return useCallback(
    (connections: Connection[]): Connection[] => {
      const outboundArrivalTime = new Date((outbound as Connection).arrivalTime)
      const returnDate = date.parse(params.returnDate as string)

      const isInbound = (inbound: Connection): boolean =>
        isAfter(new Date(inbound.departureTime), outboundArrivalTime) &&
        isSameDay(date.parse(inbound.departureTime, 'UTC'), returnDate) &&
        inbound.departureStation.code === outbound?.arrivalStation.code &&
        inbound.arrivalStation.code === outbound?.departureStation.code &&
        inbound.marketingCarrier.id === outbound?.marketingCarrier.id

      return connections.reduce<Connection[]>((availableConnections, connection) => {
        if (!isInbound(connection)) return availableConnections
        const filteredConnection = fareClassFilter ? filterFares(connection, fareClassFilter) : connection
        if (filteredConnection.fares.length) availableConnections.push(filteredConnection)

        return availableConnections
      }, [])
    },
    [fareClassFilter, outbound, params.returnDate],
  )
}
