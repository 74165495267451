import cn from 'classnames'
import React, { ForwardedRef, forwardRef, ReactElement } from 'react'

import ConnectionCalendar from '@components/ConnectionCalendar'
import Sort from '@components/JourneyList/Sort'
import LoadConnectionsButton from '@components/LoadConnectionsButton'
import useIsMobile from '@hooks/useIsMobile'
import useIsTablet from '@hooks/useIsTablet'
import bem from '@lib/bem'
import { useTranslation } from '@lib/i18n'
import { useSettings } from '@queries/settings'
import { Button, IconText } from '@ui'

interface HeaderProps {
  twoWayConnections: boolean
  outbound?: Connection | null
  onFiltersOpened: () => void
  isLoading: boolean
}

const Header = (props: HeaderProps, ref: ForwardedRef<HTMLDivElement>): ReactElement => {
  const { twoWayConnections, outbound, onFiltersOpened, isLoading } = props

  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const isTablet = useIsTablet()
  const [{ sorting, searchCalendar, filter, tripTime }] = useSettings()
  const showSort = sorting.enabled
  const showFilter = (isMobile || isTablet) && filter.enabled
  const showControlPanel = showSort || showFilter

  const getListTitle = (): string => {
    if (twoWayConnections && !outbound) return t('journeyList.outboundTrip')
    if (twoWayConnections && outbound) return t('journeyList.inboundTrip')

    return t('journeyList.trip')
  }

  const filterButton = (
    <Button variant="text" color="secondary" onClick={onFiltersOpened}>
      <IconText label={t('journeyList.filters.title')} iconName="settings" />
    </Button>
  )

  return (
    <div className={cn(bem('journey-list', 'header', { expanded: !showControlPanel }), 'column')}>
      <h3 ref={ref} className={bem('journey-list', 'header-title')}>
        {getListTitle()}
      </h3>
      {searchCalendar.enabled && <ConnectionCalendar outbound={outbound} isLoading={isLoading} />}
      {showControlPanel && (
        <div className="journey-list__header-control-panel column row-lg items-center">
          <div className="row items-center space-between w-100">
            {showSort && <Sort />}
            {showFilter && filterButton}
          </div>
        </div>
      )}
      {isMobile && tripTime.enabled && <LoadConnectionsButton loading={isLoading} />}
    </div>
  )
}

export default forwardRef(Header)
