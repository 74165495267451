import React, { ReactElement } from 'react'

import Amenities from '@components/JourneyCard/Footer/Amenities'
import Price from '@components/JourneyCard/Footer/Price'
import bem from '@lib/bem'

import '@components/JourneyCard/Footer/index.scss'

interface FooterProps {
  connection: Connection
  onToggleDetails: () => void
  onClick: (fareClassCode: string) => void
  passengersCount: number
  fare?: Fare | null
  showFares: boolean
  isAmendment: boolean
  isHeaderHidden?: boolean
}

const Footer = ({ connection, onToggleDetails, showFares, isHeaderHidden, ...rest }: FooterProps): ReactElement => {
  return (
    <>
      <Amenities connection={connection} onToggleDetails={onToggleDetails} isHeaderHidden={isHeaderHidden} />
      <div className={bem('journey-card', 'footer-features-wrapper')}>
        {!showFares && <Price connection={connection} onToggleDetails={onToggleDetails} {...rest} />}
      </div>
    </>
  )
}

export default Footer
