import { ConnectionSelectionData } from '@components/JourneyCard'
import config from '@config'
import { ConnectionsLoaderParams } from '@loaders/connections'
import { useParams } from '@stores/params'

type UseTwoStepSearchParams = {
  twoStepReturnSearch: boolean
} & Partial<
  Pick<ConnectionsLoaderParams, 'departureLocation' | 'arrivalLocation' | 'departureDate' | 'returnDate' | 'offerId'>
>

const useTwoStepSearchParams = (
  data: ConnectionSelectionData | null,
  isTwoWayTrip: boolean,
): UseTwoStepSearchParams | null => {
  const [{ retailerPartnerNumber, departureLocation, arrivalLocation, returnDate }] = useParams()

  const { connection, fareClassCode } = { ...data }

  const selectedFareObj = connection?.fares.find(fare => fare.fareClass.code === fareClassCode)
  const isTwoStepSearchAllowed = config.twoStepSearch.byRpn.includes(retailerPartnerNumber)

  if (!isTwoStepSearchAllowed || !isTwoWayTrip) return null
  if (!selectedFareObj) return { twoStepReturnSearch: true }

  return {
    departureLocation: arrivalLocation,
    arrivalLocation: departureLocation,
    returnDate: null,
    departureDate: returnDate as string,
    offerId: selectedFareObj?.id,
    twoStepReturnSearch: true,
  }
}

export default useTwoStepSearchParams
