import { getIn, useFormikContext } from 'formik'
import React, { ReactElement, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { UpdateRequest as UpdateReservation } from '@api/reservation'
import errorImage from '@images/reservations/error.png'
import ancillaryUtils from '@lib/ancillary'
import { useTranslation } from '@lib/i18n'
import reservationUtils from '@lib/reservation'
import { useReservationUpdate } from '@loaders/updateReservation'
import { CheckoutFormData } from '@pages/Checkout/hooks/useInitialFormValues'
import ReservationErrorModal from '@pages/Checkout/ReservationsErrorModal'
import Close from '@pages/Checkout/ReservationsErrorModal/Footer/Close'
import { useParams } from '@stores/params'

const UpdateReservationLoader = (): ReactElement | null => {
  const {
    values: { reservationData, passengers, ancillaries, contact, meta },
    initialValues,
    errors,
    touched,
    setFieldValue,
    isValidating,
  } = useFormikContext<CheckoutFormData>()
  const [params] = useParams()
  const { t } = useTranslation()

  const reservation = reservationUtils.getSessionStorage(params)
  const reservationValid = reservation != null && new Date() < reservation.expiresAt
  const { pathname, search } = useLocation()
  const isFieldComplete = (key: string) => getIn(errors, key) === undefined && getIn(touched, key)

  const {
    mutate: updateReservation,
    isLoading,
    reset,
    variables,
    error,
  } = useReservationUpdate({
    onSuccess: (data, requestParams) => {
      reservationValid && reservationUtils.setSessionStorage({ ...reservation, ancillaries }, params)
      reservationValid && reservationUtils.setCheckoutPath(String(reservation.id), `${pathname}${search}`)
      setFieldValue('price', data.price)

      requestParams.passengers.map(({ ancillaries }, index) => {
        ancillaries.map(ancillary => {
          const category = ancillaryUtils.getCategoryFromCode(ancillary.code)
          setFieldValue(`ancillaries.${category}.${index}.active`, true)
        })
      })
    },
    onError: () => {
      setFieldValue('ancillaries', reservation.ancillaries ?? initialValues.ancillaries)
      setFieldValue('meta.voucher', [])
    },
  })

  useEffect(() => {
    setFieldValue('isReservationLoading', isLoading)
  }, [isLoading, setFieldValue])

  useEffect(() => {
    const isEmailComplete = isFieldComplete('contact.email')
    const isInsuranceComplete = isFieldComplete('ancillaries.INSURANCE')

    const isInsuranceInitialized = ancillaries.INSURANCE?.length || variables?.passengers?.[0]?.ancillaries?.length
    const isVoucherInitialized = meta.voucher?.length || variables?.passengers?.[0]?.cards?.length

    if (!reservationData || error || !isEmailComplete || isValidating) return

    const passengersData = passengers.map((_, index) => {
      const item = ancillaries.INSURANCE?.[index]
      return {
        ancillaries: item ? [{ code: item.code, customAttributes: item.customAttributes }] : [],
        cards: meta.voucher,
      }
    })

    const data: UpdateReservation = { id: reservationData!.id, email: contact.email, passengers: passengersData }

    if (
      (isVoucherInitialized || (isInsuranceInitialized && isInsuranceComplete)) &&
      JSON.stringify(data) !== JSON.stringify(variables)
    ) {
      updateReservation(data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ancillaries.INSURANCE, contact.email, isValidating])

  if (isLoading || !error) return null

  return (
    <ReservationErrorModal
      isOpened={!!error.code}
      body={t('checkout.reservationModal.updateErrorBody')}
      title={t('checkout.reservationModal.errorHeader')}
      footer={
        <Close
          onClick={() => {
            reset()
          }}
        />
      }
      iconPath={errorImage}
    />
  )
}

export default UpdateReservationLoader
