import { Response as CreateResponse } from '@api/reservation/create'
import makeApi from '@lib/api'

export type UpdateAncillaries = Pick<Ancillary.Item, 'code' | 'customAttributes'>[]
export type UpdatedPassenger = { ancillaries: UpdateAncillaries; cards: DiscountCode.Card[] }

interface RequestBody {
  email: string
  passengers: UpdatedPassenger[]
}

type Response = CreateResponse

export interface Request extends RequestBody {
  id: number
}

export const update = async (params: Request): Promise<Response> => {
  const { id, ...body } = params

  const url = `/reservations/${id}`
  const callApi = makeApi.put<{}, RequestBody, Response>({
    type: 'reservations',
    old: url,
    new: url,
  })

  return await callApi({ body, params: {} })
}
