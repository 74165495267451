import { useFormikContext } from 'formik'
import React, { ReactElement, useEffect, useState } from 'react'

import amplitude from '@lib/analytics/amplitude'
import { ViewCheckoutPageData } from '@lib/analytics/amplitude/checkout'
import GTMDataLayer from '@lib/analytics/gtm/GTMdataLayer'
import { CheckoutFormData } from '@pages/Checkout/hooks/useInitialFormValues'
import { PriceHintsCheckoutData } from '@pages/Checkout/hooks/usePriceHints'
import { useSettings } from '@queries/settings'
import { useCheckout } from '@stores/checkout'
import { useParams } from '@stores/params'

interface Props {
  priceHintsData: PriceHintsCheckoutData
}

const getPriceHintsValue = ({
  loader,
  isVisible,
  lowerThanUsual,
}: PriceHintsCheckoutData): ViewCheckoutPageData['priceHint'] => {
  if (!loader.data) return undefined

  if (!lowerThanUsual) return 'AboveUsual'
  if (isVisible) return 'Visible'
  return 'Hidden'
}

const CheckoutAnalytics = ({ priceHintsData }: Props): ReactElement => {
  const [{ price: initialPrice, currency, retailerPartnerNumber }] = useParams()
  const [{ outbound, inbound }] = useCheckout()
  const [{ reservation }] = useSettings()
  const { values } = useFormikContext<CheckoutFormData>()
  const { isReservationLoading, isVacancyLoading, priceError, reservationData, vacancy } = values
  const [priceLoaded, setPriceLoaded] = useState<boolean>(false)

  const price = values.price ?? reservationData?.price

  useEffect(() => {
    if (!isReservationLoading && !isVacancyLoading) {
      setPriceLoaded(true)
    }
  }, [isReservationLoading, isVacancyLoading])

  useEffect(() => {
    // console.log('analytics', priceLoaded, !!outbound, !priceHintsData.loader.isLoading)
    const isVacant = reservation.enabled ? reservationData?.price?.fractional != null : vacancy?.vacant

    if (outbound && priceLoaded && !priceHintsData.loader.isLoading) {
      amplitude.checkout.viewPage({
        priceHint: getPriceHintsValue(priceHintsData),
        outbound,
        inbound,
        fareClass: values.fareClass,
        passengers: values.passengers,
        initialPrice,
        vacant: priceError == null ? !!isVacant : false,
        price,
        reason: priceError == null ? values.vacancy?.reason?.code : 'API Error',
        errorData: priceError,
        ancillaries: values.vacancy?.ancillaries.map(({ code, price, name }) => ({ code, price, name })),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outbound, priceLoaded, priceHintsData.loader.isLoading])

  useEffect(() => {
    if (!outbound) return

    GTMDataLayer.pushCheckoutEvent({
      outbound,
      inbound,
      step: 1,
      currency,
      retailerPartnerNumber,
      fareClass: values.fareClass,
      pax: values.passengers.length,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outbound])

  useEffect(() => {
    if (!price && !outbound) return

    GTMDataLayer.pushCheckoutEvent({
      outbound,
      inbound,
      step: 2,
      currency,
      retailerPartnerNumber,
      fareClass: values.fareClass,
      pax: values.passengers.length,
      price: price,
      fees: values.fees,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [price, outbound])

  return <></>
}

export default CheckoutAnalytics
