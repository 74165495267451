import React, { ReactElement } from 'react'

import bem from '@lib/bem'
import { useTranslation } from '@lib/i18n'
import { Icon } from '@ui'

interface SelectProps {
  selected: boolean
  ancillary: Ancillary.Item
  onClick: (ancillary: Ancillary.Item) => void
  removable?: boolean
}

const Select = ({ selected, ancillary, onClick, removable }: SelectProps): ReactElement => {
  const { t } = useTranslation()

  return (
    <div className="cell row-lg items-center">
      <span className="row mr-lg-2 mb-sm-2">
        <div className={bem('extras', 'ancillary', { selected })}>
          {selected && (
            <>
              <Icon name="checkout" size="large" />
              <span>{t('extras.added')}</span>
            </>
          )}
          {!selected && (
            <span
              className="extras__add"
              onClick={() => {
                onClick(ancillary)
              }}
            >
              <Icon name="plus" size="medium" />
              <span className="extras__add-text">{t('extras.add')}</span>
            </span>
          )}
        </div>
      </span>
      {selected && removable && (
        <span
          className="extras__remove row center gap-1 mt-sm-1"
          onClick={() => {
            onClick(ancillary)
          }}
        >
          <Icon name="minus" size="medium" />
          <span>{t('extras.ancillary.remove')}</span>
        </span>
      )}
    </div>
  )
}

export default Select
