import cn from 'classnames'
import React, { Fragment, ReactElement } from 'react'

import FareConditions from '@components/JourneyCard/Footer/FareConditions'
import Money from '@components/Money'
import bem from '@lib/bem'
import connectionUtils from '@lib/connection'
import { useTranslation } from '@lib/i18n'
import seatsUtils from '@lib/seats'
import utils from '@lib/utils'
import { Button } from '@ui'

import '@components/JourneyCard/Details/Fares.scss'

interface FaresProps {
  connection: Connection
  onClick: (fareClassCode: string) => void
  selectedFareClass: string | null
  displayedFareClass: string
  onFareClick: (fare: string) => void
  isAmendment: boolean
  isInbound: boolean
  disableFareFilter: boolean
}

const Fares = (props: FaresProps): ReactElement => {
  const {
    onClick,
    connection,
    selectedFareClass,
    onFareClick,
    displayedFareClass,
    isAmendment,
    isInbound,
    disableFareFilter,
  } = props
  const { t } = useTranslation()

  const filteredFares =
    selectedFareClass && isInbound && !disableFareFilter
      ? connection.fares.filter(item => item.fareClass.code === selectedFareClass)
      : connection.fares.sort((a, b) => a.price.fractional - b.price.fractional)

  return (
    <div className="journey-card__fares column center">
      {filteredFares.map(item => {
        const { id, fareClass, seatsLeft } = item

        const displayLeftTickets = seatsLeft != null && seatsUtils.showSeatsLeft(seatsLeft)
        const bookedOut = seatsLeft != null && seatsLeft === 0
        const selected = fareClass.code === displayedFareClass
        const cardStyle = bem('journey-card', 'fares-card', { selected })
        const price = connectionUtils.getPrice(connection, item)

        return (
          <Fragment key={id}>
            <div
              className={cn('row items-center space-between', cardStyle)}
              onClick={() => {
                onFareClick(fareClass.code)
              }}
            >
              <div className="journey-card__fares-card-label column">
                <span className={bem('journey-card', 'fares-card-title', { selected })}>
                  {utils.string.capitalize(fareClass.name)}
                </span>
                {displayLeftTickets && (
                  <span className="journey-card__fares-card-tickets">
                    {t('card.ticketsLeft', { count: seatsLeft })}
                  </span>
                )}
              </div>
              {bookedOut || (
                <div className="journey-card__fares-card-price row">
                  {item.conditions && item.conditions.length > 0 && <FareConditions conditions={item.conditions} />}
                  <Button
                    onClick={e => {
                      e.stopPropagation()
                      onClick(fareClass.code)
                    }}
                  >
                    <Money {...price} signed={isAmendment} />
                  </Button>
                </div>
              )}
              {bookedOut && t('card.booked')}
            </div>
          </Fragment>
        )
      })}
    </div>
  )
}

export default Fares
