import React, {
  CSSProperties,
  forwardRef,
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react'
import { VariableSizeList as List } from 'react-window'

import Item from '@components/VirtualList/Item/Index'
import useIsMobile from '@hooks/useIsMobile'
import ScrollContext from '@pages/SearchResult/ScrollContext/scrollContext'

import '@components/VirtualList/index.scss'

interface VirtualListProps<T> {
  items: T[]
  renderElement: (item: T) => ReactNode
  getId: (item: T) => string
  gap?: number
}

const INITIAL_DESKTOP_HEIGHT = 220
const INITIAL_MOBILE_HEIGHT = 260

interface OuterComponentProps {
  children: ReactNode
  style: CSSProperties
  onScroll: (event: any) => void
}

const OuterComponent = forwardRef<HTMLDivElement, OuterComponentProps>(function OuterComponent(
  { children, onScroll, ...rest },
  ref,
) {
  const { scrollObservable, scrollRef } = useContext(ScrollContext)
  const elementRef = useRef<HTMLDivElement>()

  const handleRef = (element: HTMLDivElement) => {
    /* istanbul ignore next: we need this code to remove console error, but we don't use it right now */
    if (typeof ref === 'function') {
      ref(element)
    } else if (ref) {
      ref.current = element
    }

    elementRef.current = element
  }

  useEffect(() => {
    /* istanbul ignore else */
    if (elementRef.current && scrollObservable && scrollRef?.current) {
      const top = elementRef.current.getBoundingClientRect().top + scrollRef.current.scrollTop

      return scrollObservable.subscribe(event => {
        const { clientHeight, scrollHeight, scrollTop } = event.currentTarget
        const currentTarget = { clientHeight, scrollHeight, scrollTop: Math.max(scrollTop - top, 0) }
        onScroll({ ...event, currentTarget })
      })
    }
  }, [onScroll, scrollObservable, scrollRef])

  const { height: _, overflow: _1, ...style } = rest.style

  return (
    <div {...rest} style={style} ref={handleRef}>
      {children}
    </div>
  )
})

const VirtualList = <T,>({ items, renderElement, gap, getId }: VirtualListProps<T>): ReactElement => {
  const listRef = useRef<List | null>(null)
  const rowHeights = useRef<Record<string, number>>({})
  const isMobile = useIsMobile()
  const initialHeight = useMemo(() => (isMobile ? INITIAL_MOBILE_HEIGHT : INITIAL_DESKTOP_HEIGHT), [isMobile])

  const getRowHeight = useCallback(
    (index: number): number => {
      const id = getId(items[index])
      return rowHeights.current[id] || initialHeight
    },
    [getId, initialHeight, items],
  )

  const setRowHeight = useCallback(
    (index: number, size: number) => {
      listRef.current?.resetAfterIndex(index)
      const id = getId(items[index])
      rowHeights.current = { ...rowHeights.current, [id]: size + Number(gap) }
    },
    [gap, getId, items],
  )

  const itemData = useMemo(
    () => ({
      setRowHeight,
      items,
      renderElement,
    }),
    [items, renderElement, setRowHeight],
  )

  return (
    <>
      <List
        layout="vertical"
        ref={listRef}
        estimatedItemSize={isMobile ? INITIAL_MOBILE_HEIGHT : INITIAL_DESKTOP_HEIGHT}
        itemSize={getRowHeight}
        height={document.body.scrollHeight}
        itemCount={items.length}
        width="100%"
        outerElementType={OuterComponent}
        overscanCount={isMobile ? 3 : 0}
        itemData={itemData}
      >
        {Item}
      </List>
    </>
  )
}

export default VirtualList
