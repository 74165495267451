import React, { ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'

import useMoovitIntegration from '@hooks/useMoovitIntegration'
import { useTranslation } from '@lib/i18n'
import paramsUtils from '@lib/params'
import url from '@lib/url'
import { useParams } from '@stores/params'
import { Button } from '@ui'

const Default = (): ReactElement => {
  const [params] = useParams()
  const { t } = useTranslation()
  const { enabled: isMoovitEnabled, handleBackToWallet } = useMoovitIntegration()

  const navigate = useNavigate()

  const homeUrl = url.build(['/'], paramsUtils.getPersistentUrlParams(params))

  return (
    <>
      <div className="reservations-modal__buttons-home">
        <Button
          onClick={() => {
            isMoovitEnabled ? handleBackToWallet() : navigate(`/${homeUrl}`)
          }}
          color="white"
        >
          <div className="reservations-modal__homepage-text">{t('checkout.reservationModal.homePage')}</div>
        </Button>
      </div>
      <div className="reservations-modal__buttons-restart">
        <Button
          onClick={() => {
            navigate(-1)
          }}
        >
          {t('checkout.reservationModal.restartSearch')}
        </Button>
      </div>
    </>
  )
}

export default Default
