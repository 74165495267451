import cn from 'classnames'
import { FormikConfig, FormikProps, useFormikContext } from 'formik'
import React, { ReactElement, RefObject, useEffect, useMemo, useRef } from 'react'

import { FinishPaymentParams } from '@hooks/useBookingFlow'
import useIsMobile from '@hooks/useIsMobile'
import useIsTablet from '@hooks/useIsTablet'
import useSearchParams from '@hooks/useSearchParams'
import bem from '@lib/bem'
import { useTranslation } from '@lib/i18n'
import BookingDetails from '@pages/Checkout/BookingDetails'
import SubmitSection from '@pages/Checkout/BookingDetails/SubmitSection'
import ExpressFields from '@pages/Checkout/express/Fields'
import ExpressExpiredModal from '@pages/Checkout/ExpressExpiredModal'
import CheckoutFields from '@pages/Checkout/Fields'
import CheckoutAnalytics from '@pages/Checkout/Form/Analytics'
import Banner from '@pages/Checkout/Form/Banner'
import CarrierLoader from '@pages/Checkout/Form/CarrierLoader'
import Fingerprint from '@pages/Checkout/Form/Fingerprint'
import InstallmentsLoader from '@pages/Checkout/Form/InstallmentsLoader'
import NethoneLoader from '@pages/Checkout/Form/NethoneLoader'
import PriceDiscrepancy from '@pages/Checkout/Form/PriceDiscrepancy'
import PriceHints from '@pages/Checkout/Form/PriceHints'
import CheckoutFormProvider from '@pages/Checkout/Form/Provider'
import ScrollToError from '@pages/Checkout/Form/ScrollToError'
import UpdateReservationLoader from '@pages/Checkout/Form/UpdateReservationLoader'
import VacancyLoader from '@pages/Checkout/Form/VacancyLoader'
import useCarriers from '@pages/Checkout/hooks/useCarriers'
import { useExpressTimer } from '@pages/Checkout/hooks/useExpressTimer'
import { CheckoutFormData } from '@pages/Checkout/hooks/useInitialFormValues'
import usePriceHints from '@pages/Checkout/hooks/usePriceHints'
import NewConnections from '@pages/Checkout/NewConnections'
import PaymentErrorModal from '@pages/Checkout/Payment/ErrorModal'
import PaymentOptionsField from '@pages/Checkout/Payment/OptionsField'
import Timer, { TimerConfig } from '@pages/Checkout/Timer'
import { useSettings } from '@queries/settings'
import { useCheckout } from '@stores/checkout'
import { useParams } from '@stores/params'

import '@pages/Checkout/Form/index.scss'

export interface FormContentProps {
  isLoading: boolean
  error?: Error | null
  warning: string | null
  setWarning: (warning: string | null) => void
  confirmationOnly?: boolean
  finishPayment: (params: FinishPaymentParams) => void
  timer?: TimerConfig | null
}

export interface CheckoutFormProps extends Omit<FormContentProps, 'warning' | 'setWarning'> {
  submit: (data: CheckoutFormData) => Promise<void> | void
  validate: FormikConfig<CheckoutFormData>['validate']
  innerRef: RefObject<FormikProps<CheckoutFormData>>
  isRefund?: (data: CheckoutFormData) => boolean
}

const FormContent = (props: CheckoutFormProps): ReactElement => {
  const [{ error: initialError, ...searchParams }, setSearchParams] = useSearchParams()
  const { finishPayment, timer: propsTimer, isRefund } = props
  const { values, setFieldValue } = useFormikContext<CheckoutFormData>()

  const [{ reservation }] = useSettings()
  const [{ express }] = useParams()
  const [{ paymentInstance }, setCheckout] = useCheckout()
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const isTablet = useIsTablet()
  const mobileOrTablet = useMemo(() => isMobile || isTablet, [isMobile, isTablet])
  const sideSectionRef = useRef<HTMLDivElement>(null)
  const isBannerShow = t('checkout.banner.text', { defaultValue: '' })

  const { codes, handleSuccess, handleLoading, isLoading: isCarriersLoading, carriers } = useCarriers()

  const { timer: expressTimer, expired, period } = useExpressTimer()
  const timer = propsTimer ?? (express ? expressTimer : null)
  const showPayment = isRefund ? !isRefund(values) : true

  const observer = useMemo(
    () =>
      new ResizeObserver(entries => {
        const position = window.innerHeight - entries[0].contentRect.height
        /* istanbul ignore else */
        if (sideSectionRef.current) sideSectionRef.current.style.top = `${position > 0 ? 0 : position}px`
      }),
    [],
  )

  useEffect(() => {
    sideSectionRef.current && observer.observe(sideSectionRef.current)

    return () => {
      observer.disconnect()
    }
  }, [observer])

  const carrierLoaders = useMemo(
    () =>
      codes.map(item => (
        <CarrierLoader key={item} marketingCarrierCode={item} onLoading={handleLoading} onSuccess={handleSuccess} />
      )),
    [codes, handleLoading, handleSuccess],
  )

  useEffect(() => {
    if (!showPayment) {
      setFieldValue('paymentMethod', null)
      setFieldValue('paymentMethodData', null)
      setCheckout({ paymentInstance: null })
    }
  }, [setCheckout, setFieldValue, showPayment])

  const priceHints = usePriceHints()

  return (
    <>
      {carrierLoaders}
      {paymentInstance?.extraContent}
      <PaymentErrorModal
        opened={initialError}
        onClose={() => {
          setSearchParams(searchParams, { replace: true })
        }}
      />
      {express && <ExpressExpiredModal opened={expired} period={period} />}
      <InstallmentsLoader />
      <CheckoutAnalytics priceHintsData={priceHints} />
      <Fingerprint />
      <ScrollToError />
      <VacancyLoader />
      <NethoneLoader />
      <NewConnections />
      {reservation.enabled && <UpdateReservationLoader />}
      <div className="checkout-form">
        <div className="row gap-4 start column-sm">
          <div className="cell-8 cell-sm-12">
            <div className="column gap-4">
              {priceHints.isVisible && (
                <div className="cell">
                  <div className={cn(bem('checkout', 'section'), 'px-3 py-2')}>
                    <PriceHints amount={priceHints.relative} type="relative" />
                  </div>
                </div>
              )}
              <PriceDiscrepancy />
              {isBannerShow && (
                <div className="cell">
                  <Banner />
                </div>
              )}
              {mobileOrTablet && timer && (
                <div className="cell">
                  <Timer {...timer} />
                </div>
              )}
              {!express && <CheckoutFields />}
              {express && <ExpressFields />}
              {showPayment && (
                <div className="cell">
                  <div className={bem('checkout', 'section')}>
                    <h3>{t('checkout.paymentMethod')}</h3>
                    <PaymentOptionsField finishPayment={finishPayment} />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="cell-4 cell-sm-12">
            <div ref={sideSectionRef} className="column gap-4 checkout-form__trip-details">
              {!mobileOrTablet && timer && (
                <div className="cell">
                  <Timer {...timer} />
                </div>
              )}
              <div className="cell">
                <div className={bem('checkout', 'section')}>
                  <BookingDetails express={express} isCarriersLoading={isCarriersLoading} carriers={carriers} />
                  <SubmitSection moneySaved={priceHints.isVisible ? priceHints.absolute : undefined} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const CheckoutForm = (props: CheckoutFormProps): ReactElement => {
  const { submit, innerRef, isLoading, error, validate, isRefund } = props
  const [{ reservation }] = useSettings()
  const isPriceLoaded = (data: CheckoutFormData): boolean =>
    reservation.enabled ? data?.reservationData?.price != null : !!data.vacancy?.vacant

  return (
    <CheckoutFormProvider
      error={error}
      innerRef={innerRef}
      submit={submit}
      isLoading={isLoading}
      canSubmit={isPriceLoaded}
      validate={validate}
      isRefund={isRefund}
    >
      <FormContent {...props} />
    </CheckoutFormProvider>
  )
}

export default CheckoutForm
