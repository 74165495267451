import connectionUtils from '@lib/connection'
import { useSettings } from '@queries/settings'
import { PassengerData, useCheckout } from '@stores/checkout'

const DEFAULT_TYPE = 'PNOS'

export const useInitialCheckoutPassenger = (): ((pax?: Passenger.Param) => PassengerData) => {
  const [{ outbound }] = useCheckout()
  const [{ governmentId, quickReservation }] = useSettings()

  const governmentIdEnabled = quickReservation.enabled
    ? connectionUtils.isInternational(outbound as Connection)
    : governmentId.enabled
  const getType = (pax?: Passenger.Param): string => pax?.type ?? DEFAULT_TYPE
  const getAge = (paxType: string, age: 'minAge' | 'maxAge'): number | undefined =>
    outbound?.marketingCarrier.passengerTypes?.find(type => type.code === paxType)?.[age]

  return (pax?: Passenger.Param) => ({
    firstName: pax?.firstName ?? '',
    lastName: pax?.lastName ?? '',
    id: Math.random(),
    type: getType(pax),
    governmentId: null,
    governmentIdType: governmentIdEnabled ? governmentId?.default : null,
    governmentIdValidityExpiration: null,
    governmentIdIssuingState: null,
    governmentIdIssuingCountry: null,
    nationality: null,
    pax: 1,
    birthdate: null,
    cards: pax?.cards ?? [],
    maxAge: getAge(getType(pax), 'maxAge'),
    minAge: getAge(getType(pax), 'minAge'),
  })
}
