import cn from 'classnames'
import React, { ReactElement } from 'react'

import TripTimePreview from '@components/TripTimePreview'

interface BodyProps {
  connection: Connection
  fareSelection: boolean
  lineDataEnabled: boolean
  onClick?: (fareClassCode: string) => void
  isHeaderHidden?: boolean
}

const Body = ({ connection, fareSelection, lineDataEnabled, onClick, isHeaderHidden }: BodyProps): ReactElement => {
  const { line, linePrefix } = connection.segments[0]
  const { departureTime, arrivalTime, departureStation, arrivalStation, cheapestFareClassCode } = connection

  return (
    <div className={cn({ 'pt-2 pt-lg-4': isHeaderHidden })} onClick={() => onClick?.(cheapestFareClassCode)}>
      <TripTimePreview
        fareSelection={fareSelection}
        departureTime={departureTime}
        arrivalTime={arrivalTime}
        departureStation={departureStation.name}
        arrivalStation={arrivalStation.name}
        departureTimezone={departureStation.timeZone}
        arrivalTimezone={arrivalStation.timeZone}
        lineData={lineDataEnabled ? { line, linePrefix } : undefined}
      />
    </div>
  )
}

export default Body
