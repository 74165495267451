import { useFormikContext } from 'formik'
import React, { ReactElement, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Counter from '@components/Counter'
import useIsMobile from '@hooks/useIsMobile'
import louvre from '@images/extras/louvre.png'
import pompidou from '@images/extras/pompidou.png'
import seine from '@images/extras/seine.png'
import amplitude from '@lib/analytics/amplitude'
import Card from '@pages/Checkout/Extras/Card'
import Description from '@pages/Checkout/Extras/Card/Description'
import { CheckoutFormData } from '@pages/Checkout/hooks/useInitialFormValues'
import { Icon } from '@ui'

interface AncillaryProps {
  name: string
  title: string
  price: Money
  icon: string
  description: string
}

const Ancillary = ({ name, icon, price, title, description }: AncillaryProps): ReactElement => {
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const [count, setCount] = useState<number>(0)
  const { values } = useFormikContext<CheckoutFormData>()

  const handleChange = (value: number): void => {
    setCount(value)
    amplitude.checkout.changeAncillary(name, value)
  }

  const warning = (
    <div className="extras__seats-warning-message row gap-1 mb-2">
      <Icon name="alert" size="small" />
      <span>{t('extras.mockedAncillaries.warning')}</span>
    </div>
  )

  return (
    <Card
      icon={icon}
      title={title}
      price={price}
      description={isMobile && <Description title={title} description={description} />}
      warning={!!count && warning}
      pricePerPassenger
      controls={
        <div className="row gap-3">
          {!isMobile && <Description title={title} description={description} />}
          {<Counter min={0} max={values.passengers.length} value={count} onChange={handleChange} />}
        </div>
      }
    />
  )
}

const Activities = (): ReactElement => {
  const { t } = useTranslation()

  const activities = useMemo(() => {
    const mock = [
      {
        name: 'Louvre Entrance',
        icon: louvre,
        price: { fractional: 2200, currency: 'EUR' },
        title: t('extras.mockedAncillaries.louvre.title'),
        description: t('extras.mockedAncillaries.louvre.description'),
      },
      {
        name: 'Seine Cruise',
        icon: seine,
        price: { fractional: 1900, currency: 'EUR' },
        title: t('extras.mockedAncillaries.seine.title'),
        description: t('extras.mockedAncillaries.seine.description'),
      },
      {
        name: 'Pompidou Entrance',
        icon: pompidou,
        price: { fractional: 1500, currency: 'EUR' },
        title: t('extras.mockedAncillaries.pompidou.title'),
        description: t('extras.mockedAncillaries.pompidou.description'),
      },
    ]

    const start = Math.floor(Math.random() * (mock.length + 1))
    const sliced = mock.slice(start, start + 1)
    /* istanbul ignore next */
    return sliced.length > 0 ? sliced : mock
  }, [t])

  return (
    <>
      {activities.map(item => (
        <div key={item.icon} className="extras__section">
          <Ancillary {...item} price={item.price as Money} />
        </div>
      ))}
    </>
  )
}

export default Activities
