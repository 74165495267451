import React, { ReactElement, ReactNode } from 'react'

import bem from '@lib/bem'
import Body from '@pages/Checkout/Extras/Card/Body'

import '@pages/Checkout/Extras/Card/index.scss'

interface CardProps {
  icon?: string
  title?: string
  description?: ReactNode | string
  warning?: ReactNode
  price?: Money | Money[]
  controls: ReactNode
  details?: ReactNode
  onClick?: () => void
  grouped?: boolean
  pricePerPassenger?: boolean
}

const Card = (props: CardProps): ReactElement => {
  const { icon, title, description, price, controls, onClick, details, grouped, warning, pricePerPassenger } = props

  return (
    <div className={bem('extras', 'card')}>
      {icon && (
        <div className="cell-2 cell-lg-1 mr-3">
          <img src={icon} alt="ancillaries icon" />
        </div>
      )}
      <Body
        title={title}
        description={description}
        controls={controls}
        price={price}
        onClick={onClick}
        details={details}
        grouped={grouped}
        warning={warning}
        pricePerPassenger={pricePerPassenger}
      />
    </div>
  )
}

export default Card
