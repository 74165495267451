import cn from 'classnames'
import React, { ReactElement } from 'react'

import BackButton from '@components/BackButton'
import PageWrapper from '@components/PageWrapper'
import RequiredResource from '@components/RequiredResource'
import bem from '@lib/bem'
import { useTranslation } from '@lib/i18n'
import reservationUtils from '@lib/reservation'
import { useCheckoutConnectionsLoader } from '@loaders/checkoutConnection'
import { useReservationConfirm } from '@loaders/confirmReservation'
import AmendmentForm from '@pages/Checkout/Amendment'
import CheckoutForm from '@pages/Checkout/Form'
import SubmitBooking from '@pages/Checkout/Form/SubmitBooking'
import SubmitReservation from '@pages/Checkout/Form/SubmitReservation'
import { useSettings } from '@queries/settings'
import { useCheckout } from '@stores/checkout'
import { useParams } from '@stores/params'

import '@pages/Checkout/index.scss'

const App = (): ReactElement => {
  const [params] = useParams()
  const { t } = useTranslation()
  const [{ reservation, quickReservation }] = useSettings()
  const [, setCheckout] = useCheckout()
  const reservationEnabled = reservation.enabled || quickReservation.enabled
  const isCurrencyChangeAllowed = !reservation.enabled

  const connectionLoader = useCheckoutConnectionsLoader(params, {
    onSuccess: data => {
      setCheckout(data)
    },
  })

  return (
    <RequiredResource
      loader={connectionLoader}
      isValidData={() => !!params.departureLocation && !!params.arrivalLocation}
    >
      <PageWrapper className="checkout-wrapper" currencyChangeAllowed={isCurrencyChangeAllowed}>
        <div className="checkout">
          <div id="bg-line" className={cn({ dense: params.express })} />
          <div id="page" className="column mb-2">
            <div className="cell">
              <BackButton title={t('checkout.backButton')} className={params.mode} />
            </div>
            <div className="cell">
              <div className={bem('checkout', 'content')}>
                {reservationEnabled && !params.bookingId && (
                  <SubmitReservation
                    form={CheckoutForm}
                    submitHook={useReservationConfirm}
                    transformParams={reservationUtils.buildConfirmParams}
                  />
                )}
                {!reservationEnabled && !params.bookingId && <SubmitBooking />}
                {params.bookingId && <AmendmentForm isLoading={connectionLoader.isLoading} />}
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    </RequiredResource>
  )
}

export default App
