import { ReactElement, ReactNode, useCallback, useEffect, useMemo, useRef } from 'react'

import { PaymentMethodStatus } from '@enums'
import { FinishPaymentParams, PaymentEvents } from '@hooks/useBookingFlow'
import utils from '@lib/utils'
import { useAdyenMethods } from '@pages/Checkout/hooks/Payment/Adyen/useAdyenMethods'
import { useCommonMethods } from '@pages/Checkout/hooks/Payment/Common/useCommonMethods'
import { useVGSMethods } from '@pages/Checkout/hooks/Payment/VGS/useVGSMethods'
import { useSettings } from '@queries/settings'
import { ExpandableRadioOption } from '@ui/RadioGroup/Expandable'

export type OptionType = ExpandableRadioOption<PaymentMethodType>

export interface PaymentMethod<TEvents extends PaymentEvents = PaymentEvents> {
  getOption: () => OptionType | OptionType[] | null | undefined
  status: PaymentMethodStatus
  extraContent?: ReactNode
  getPayButton?: () => ReactElement
  error?: Error | null
  isLoading?: boolean
  isSubmitting?: boolean
  on?: TEvents
}

export type PaymentProvider = Record<string, PaymentMethod>

interface PaymentMethodsHookResult {
  available: PaymentMethod[]
  selected?: PaymentMethod | null
}

interface PaymentMethodsHookParams {
  methodType?: PaymentMethodType | null
  confirmationOnly?: boolean
  finishPayment: (params: FinishPaymentParams) => void
}

const methodsOrder = [
  'savedCreditCard',
  'pix',
  'applePay',
  'googlePay',
  'paypal',
  'creditCard',
  'storedPayment',
  'blik',
  'holdReservation',
  'terminal',
  'cash',
  'invoice',
]

export const useBookingPayment = ({
  methodType,
  confirmationOnly,
  finishPayment,
}: PaymentMethodsHookParams): PaymentMethodsHookResult => {
  const [
    {
      paymentMethods,
      paymentProvider: { code: primaryProvider },
      holdBooking,
      amtrackSavedCards,
    },
  ] = useSettings()
  const selectedMethodRef = useRef<PaymentMethod | null>()

  const adyen = useAdyenMethods({ selectedMethod: selectedMethodRef, finishPayment })
  const vgs = useVGSMethods()
  const common = useCommonMethods()

  const providers = useMemo(() => ({ adyen, vgs, common }), [adyen, common, vgs])
  const findMethod = useCallback(
    (paymentMethodType: PaymentMethodType): PaymentMethod | undefined => {
      const type = utils.string.toCamelcase(paymentMethodType.split(':')[0])

      return providers[primaryProvider][type] ?? Object.values(providers).find(methods => methods[type] != null)?.[type]
    },
    [primaryProvider, providers],
  )

  const isAvailable = useCallback(
    (method: PaymentMethodType): boolean => {
      switch (method) {
        case 'savedCreditCard':
          return amtrackSavedCards.enabled
        case 'holdReservation':
          return !confirmationOnly && holdBooking.enabled
        default:
          return paymentMethods[method]
      }
    },
    [amtrackSavedCards.enabled, confirmationOnly, holdBooking.enabled, paymentMethods],
  )

  const availableMethods = useMemo(() => {
    return methodsOrder.reduce<PaymentMethod[]>((methods, type) => {
      const method = findMethod(type)

      return method && isAvailable(type) ? [...methods, method] : methods
    }, [])
  }, [findMethod, isAvailable])

  const selectedMethod = useMemo(() => (methodType ? findMethod(methodType) : null), [findMethod, methodType])
  useEffect(() => {
    selectedMethodRef.current = selectedMethod
  }, [selectedMethod])

  return { available: availableMethods, selected: selectedMethod }
}
