import React, { ReactElement } from 'react'
import { Link } from 'react-router-dom'

import useMoovitIntegration from '@hooks/useMoovitIntegration'
import qs from '@lib/queryString'
import { useSettings } from '@queries/settings'
import { useParams } from '@stores/params'

interface LogoLinkProps {
  children: ReactElement
}

const LogoLink = ({ children }: LogoLinkProps): ReactElement => {
  const [{ logo }] = useSettings()
  const [{ retailerPartnerNumber, marketingCarrierCode }] = useParams()
  const { enabled: isMoovitEnabled, handleBackToWallet } = useMoovitIntegration()

  const { redirectionUrl } = logo

  if (isMoovitEnabled) return <a onClick={handleBackToWallet}>{children}</a>
  if (redirectionUrl) return <a href={redirectionUrl}>{children}</a>

  return (
    <Link to={{ pathname: '/', search: qs.stringify({ retailerPartnerNumber, marketingCarrierCode }) }}>
      {children}
    </Link>
  )
}

export default LogoLink
