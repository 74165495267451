import cn from 'classnames'
import React, { useMemo } from 'react'

import bem from '@lib/bem'
import currencyUtils from '@lib/currency'
import { Trans } from '@lib/i18n'
import { useParams } from '@stores/params'
import { Icon } from '@ui'

import '@pages/Checkout/Form/PriceHints/index.scss'

interface PriceHintsProps {
  amount: number
  type: 'relative' | 'absolute'
}

const PriceHintMessage = ({ amount: _amount, type }: PriceHintsProps) => {
  const [{ currency }] = useParams()
  const amount = useMemo(
    () => (type === 'relative' ? Math.round(_amount * 100) : currencyUtils.create(_amount, currency).format()),
    [_amount, currency, type],
  )

  return (
    <div className="row gap-2" data-tag="price-hint">
      <div className="cell no-grow">
        <div className={bem('price-hint', 'icon')}>
          {type === 'relative' ? <Icon name="trending-down" size="large" /> : <Icon name="money-pig" size="medium" />}
        </div>
      </div>
      <div className={cn('body-14 cell center', { ['price-hint__highlight body-16']: type === 'relative' })}>
        <Trans
          i18nKey={`checkout.priceHint.${type}`}
          values={{ amount }}
          components={[<span key="" className={bem('price-hint', 'amount')} />]}
        />
      </div>
    </div>
  )
}

export default PriceHintMessage
