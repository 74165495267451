import React, { ReactElement } from 'react'

import FareConditions from '@components/JourneyCard/Footer/FareConditions'
import Money from '@components/Money'
import useIsMobile from '@hooks/useIsMobile'
import bem from '@lib/bem'
import connectionUtils from '@lib/connection'
import { useTranslation } from '@lib/i18n'
import seatsUtils from '@lib/seats'
import utils from '@lib/utils'
import { Button, Icon } from '@ui'

interface PriceProps {
  connection: Connection
  passengersCount: number
  onClick: (fareClassCode: string) => void
  onToggleDetails: () => void
  fare?: Fare | null
  isAmendment: boolean
}

const Price = (props: PriceProps): ReactElement => {
  const { connection, passengersCount, fare, onClick, isAmendment, onToggleDetails } = props

  const isMobile = useIsMobile()
  const { t } = useTranslation()
  const { totalSeatsLeft } = connection
  const displayLeftTickets = totalSeatsLeft != null && seatsUtils.showSeatsLeft(totalSeatsLeft)

  /* istanbul ignore next */
  const handleClick = (): void => {
    onClick(fare?.fareClass.code ?? connection.cheapestFareClassCode)
  }

  const price = connectionUtils.getPrice(connection, fare)

  return (
    <>
      <div className={bem('journey-card', 'footer-features')}>
        {displayLeftTickets && (
          <div className={bem('journey-card', 'footer-features-tickets-left')}>
            {t('card.ticketsLeft', { count: totalSeatsLeft })}
          </div>
        )}
        <div className="row center items-center">
          <div onClick={onToggleDetails} className={bem('journey-card', 'footer-features-info')}>
            <Icon name="seat" size="medium" />
            <span className={bem('journey-card', 'footer-features-title')}>
              {utils.string.capitalize(fare?.fareClass?.name ?? '')}
            </span>
          </div>
          {fare?.conditions && fare.conditions.length > 0 && <FareConditions conditions={fare.conditions} />}
        </div>
        <div onClick={onToggleDetails} className="journey-card__footer-passengers row">
          <Icon name="passenger" size={isMobile ? 'small' : 'large'} />
          <span>{passengersCount}</span>
        </div>
      </div>
      <div className={bem('journey-card', 'footer-price')}>
        <Button disabled={connection.bookedOut} onClick={handleClick}>
          <div className={bem('journey-card', 'footer-price-total')}>
            <Money {...price} signed={isAmendment} />
          </div>
        </Button>
      </div>
    </>
  )
}

export default Price
